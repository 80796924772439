import * as React from "react";
import { Link } from "react-router-dom";
import { Loading, Navbar, TailwindContainer, BackgroundLayer, Hero, SneakPeeks, SoulToken, RoadMap, DoxxedTeam, Footer, SectionDivider } from "../Components";
import "./styles.css";
export default function Home() {
  return (
    <>
    <Navbar />
    <BackgroundLayer className="min-h-screen homeGradient">
      <Hero>
        <p>
         Cyber District’s mission is to collectively pool money and buy as much $LAND as possible in The Sandbox.
         This is to back up our utility token $SOUL, to provide maximum value to token holders.
         The token will be 100% backed by the land we buy and 1 $SOUL will always be equal to 1 $SOUL.
         If the $LAND in the community wallet were to transfer ownership (via a buyout, in. . . say, a few years),
         then everything would be distributed amongst $SOUL token holders.
        </p>
      </Hero>
      
      <SneakPeeks />
      <SoulToken>
        $SOUL tokens will have many utilities for our holders. They can be used to get free mints, whitelist spots and we’re working on much more! For games built on our land in the future, holders can possibly get
        in-game perks and assets. Anytime $SOUL is used for utilities, it will be burned making it a deflationary token. Over time supply will decrease and the $LAND will appreciate, leading to some interesting tokenomics.
        5% of the mint will go towards giveaways which will be decided by you, the community.
      </SoulToken>

      <RoadMap/>
      <DoxxedTeam>Laying the foundation and paving the future of the Cyber District.</DoxxedTeam>   
    </BackgroundLayer>   
    <Footer />
    </>
  );
}