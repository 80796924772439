import * as React from "react";
import { Link } from "react-router-dom";
import { Loading, Navbar, TailwindContainer, Footer, Finances, Tokenomics, UtilityList } from "../Components";

export default function Financials() {
  return (
    <>
    <Navbar />
      <div className="relative bg-black-blue min-h-screen">
        <Finances />
        <Tokenomics />        
        <UtilityList />
      </div>
    <Footer />
    </>
  );
}