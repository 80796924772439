import React from 'react';
import { TailwindContainer, BackgroundLayer, Card, LinkWrapper, Button } from '../../';

function SoulToken({children, className, title="Soul Token", ...props}) {

  const coin = <img className="w-[30%]" src="/Assets/Images/coin.png" />;
  const coinStack = <img className="w-full" src="/Assets/Images/coin-stack.png" />;

  return (
    <BackgroundLayer className={`md:py-8 ${className}`} {...props}>
      <TailwindContainer>
        <Card className="bg-graffiti-blue-high-opacity md:rounded-xl py-4 md:py-8 md:px-6 text-white">
          <div className="flex flex-col px-6 md:flex-row flex-wrap sm:flex-nowrap gap-2 md:gap-6 justify-center items-center">
              
            <div className="hidden md:flex flex-col flex-nowrap justify-center items-center gap-2 w-full max-w-xs">
              {coinStack}
            </div>
            <div className="flex md:hidden flex-row flex-nowrap justify-center items-center">
              {coin}
            </div>
            <div className="text-center md:text-left md:max-w-sm lg:max-w-lg">
            <h2 class="text-2xl font-extrabold mb-2">{title}</h2>
            <p class="text-sm md:text-base ">
              {children}
            </p>

            <br/>
            <a href="/financials#soul-utilities" className="text-sm md:text-base transition duration-700 bg-graffiti-pink-low-opacity hover:bg-graffiti-pink border border-graffiti-pink font-semibold px-4 py-2 rounded-md">
              Learn More About Soul Tokens
            </a>
            </div>

          </div>
        </Card>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default SoulToken

