import React from 'react'
import { Link } from "react-router-dom";
import { Loading } from '../../';

const Button = ({className, href, children, loading, ...props}) => {
    const classes = ` ${className}`;
    if (loading){
        return <Loading />
    }
    if (href) {
        return (
            <a href={href} className={classes} {...props}>{children}</a>
        )
    }
    return (
        <button className={classes} {...props}>{children}</button>
    )
}

const LinkWrapper = ({className, children, loading, to, href, ...props}) => {
    if(loading){
        return <Loading/>;
    }
    return(
        <Link to={to} className={` ${className}`} {...props}>
            {children}
        </Link>
    );
}

export {Button, LinkWrapper};