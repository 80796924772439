import React,{useState} from 'react';
import { TailwindContainer,BackgroundLayer,Card} from '../../';
import { classNames } from '../../../Utilities';

const utilities = [
  {
    id: 1,
    text:"One on one call with the founders",
  },
  {
    id: 2,
    text:"Discount for next season’s mint",
  },
  {
    id: 3,
    text:"Whitelist for next season",
  },
  {
    id: 4,
    text:"Raffle for blue chip NFTs, Ethereum giveaways",
  },
  {
    id: 5,
    text:"Unclaimed tokens from paper hands will be burnt",
  },

]
function UtilityList({children,title="Utilities of $Soul Tokens",subtitle="*More details TBA near token launch. Check back here or on our discord",className,...props}) {
  const [activeId,setActiveId] = useState(-1);

  const onPress= (id) => {
    if(id == activeId){
      setActiveId(-1);
      return;
    }
    setActiveId(id);
  }

  return (
    <BackgroundLayer className={` ${className}`} {...props}>
      
      <TailwindContainer id="soul-utilities" className="mt-8 md:px-8 mx-auto">
        <h2 className="text-center  font-semibold text-white text-3xl mb-2  px-4">
          {title}
        </h2>
        <p class="text-lg text-center text-gray-500 mb-6  px-4">{subtitle}</p>
        <div className="mt-2 md:mb-4 mx-auto grid gap-4 p-4 bg-graffiti-blue-med-opacity border-t-2 rounded-t-lg md:border-2 border-graffiti-pink md:rounded-xl">
          {
            utilities.map((utility ,index) => 
              <>
                <div className="flex w-full text-center items-center justify-center text-white text-xl" key={utility.id}>
                    {utility.text}
                </div>
              </>
            )
          }
             
            
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default UtilityList
