import * as React from "react";
import { Link } from "react-router-dom";
import { Loading, Navbar, TailwindContainer, Footer } from "../Components";

export default function TermsOfService() {
  return (
    <>
    <Navbar />
      <div className="relative bg-black-blue h-screen">
        <img className="absolute w-[90%] md:h-[75%] lg:w-fit top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto" src="/Assets/Images/coming-soon.png" />        
      </div>
    <Footer />
    </>
  );
}