import * as React from "react";
import { Link } from "react-router-dom";
import { Loading, Navbar, Footer, BackgroundLayer, TailwindContainer, AboutDistrict, FutureVision, LandUtility, Story} from "../Components";


export default function About() {
  return (
    <>
      <BackgroundLayer className="min-h-screen bg-black-blue py-4">
         <Navbar/>
         <div className="flex flex-col gap-8 pb-8 md:gap-16 md:pb-16">
          <AboutDistrict id="about-district">
            We are an NFT project with multiple seasonal mints. Each mint will be 5,000 unique, randomly generated NFTs.
            Money received in the community wallet will be used to purchase $LAND in The Sandbox. $SOUL will be our fractionalized
            utility token, 100% backed by Sandbox $LAND owned in the community wallet. Always remember 1 $SOUL = 1 $SOUL! Cyber District holders will receive $SOUL by holding their NFTs.
          </AboutDistrict>
         
          <LandUtility>
            $LAND bought in the community wallet will be used to rent land to game developers.
            Furthermore, $SAND tokens can be staked on this land as well.
            This is NOT a monetary incentive and any positive returns will be used to purchase more land,
            resulting in no monetary benefits to holders. Why The Sandbox, you ask? Check out the analysis on the Why Sandbox channel in our discord. {/*<a className="underline text-blue-500" href="">here.</a>*/}
          </LandUtility>
          <FutureVision>
            We envision being a bridge between NFTs and the metaverse.
            We plan to provide real-world value to our holders through utilities which will be revealed in the near future.
            Through our holders, we plan to change the future of Sandbox by giving innovative developers a space to build
            unique games on a blockchain and in a web3 ecosystem. Us founders will be holders ourselves and have a very clear
            vision of growing this project in terms of value provided to its holders as much as possible.
            <br/>
            <br/>
            In the case of a buyout in the future, there will be distribution of value amongst token holders.
            A certain amount of $SOUL will be used for Project Zero ensuring great real-world utilities for our NFT holders forever!!
            (details to be announced in a few years).

            {/*
            If the $LAND in the community wallet were to transfer ownership
            [via a buyout, in. . . say, a few years], then everything would be distributed to the $SOUL token holders
            (because the tokens are 100% backed by the land; that being said, 1$SOUL = 1$SOUL). A certain amount of $SOUL
            will be used for Project Zero ensuring great real-world utilities for our NFT holders forever!!
            (details to be announced in 4-5yrs)*/}
          </FutureVision>
           <Story>
                          
              On a cold winter day, four humans collectively experienced something peculiar, which changed their lives forever. . . and possibly yours as well. This is a shortened account of those four humans, the District Architects, and the future of the soul community. 
<br /><br />
              At first, GeekMod, Zeltrix, ZEHN, and Parth had gone about their regular lives; researching and trading NFTs, drawing, and coding. When their computers started glitching, they quickly brushed it off. And almost as if they were in a simulation, they watched their bodies glitch right in front of their eyes. Extremely confused, they started exploring different possibilities.
<br /><br />
              Before they could come to any conclusion. . . BOOM.
<br /><br />
              Their bodies were instantly transported to uninhabited areas: Zeltrix and geekmod in the middle of a desert, Parth in the middle of an island, and ZEHN on what appeared to be a glacier.
<br /><br />
              “Where am I?”<br />
              “What is going on?”<br />
              “How did I end up here?”<br />
              “Am I dreaming?”<br />
              "Why do I hear all these voices in my head?" <br />
              "Wait, who are you?" 
<br /><br />
              Chaos ensued as they heard each other’s voices in their heads loud and clear. Once the dust settled, they figured out who the voices in their head were. They decided to meet and search for answers.
<br /><br />
              It took them all a month to come together. The moment they stepped into each others' presence, an aura emitted from Zeltrix's palms, illuminating the dark night. Taken aback, he tripped and used Zehn for support. As soon as he touched Zehn's shoulder, Zehn glitched and his eyes went white before he collapsed on the floor. Motionless and completely silent, the others gazed in disbelief. 
<br /><br />
              As if a water droplet had fallen in reverse, the very first extracted soul emerged out of his spine.
<br /><br />
              From this moment onward, they dedicated their days to understanding this new power they had stumbled upon. After intense research, they realized that they had the power to extract souls into the metaverse. 
<br /><br />
              Parth, the District Architect, tinkered with all sorts of materials and their effect on souls. He found a way to enhance the souls and Zehn spent hours making the enhancements' design as efficient and fashionable as possible. Zeltrix and GeekMod researched the metaverse and NFTs to find the best possible use of their powers. 
<br /><br />
              All throughout, the four of them had long conversations about the future of the metaverse and where souls could fit in the puzzle. 
<br /><br />
              They couldn't. 
<br /><br />
              However, they never gave up and eventually found a solution. 
<br /><br />
              "If we can’t fit in, we'll create our own place – our own district," they thought. 
<br /><br />
              They all settled on one vision: creating a Cyber District of rebellious enhanced souls who would use their powers to take over the metaverse and radically revolutionize it.
<br /><br />
              Their next task was to find other misfits who would comprise the district; those who believed in the same vision. Those who were capable enough to handle the enhancements. Those who were wise enough to not only be a part of the future, but wise enough to build it. Those who were courageous enough to take the first step towards change. Those whose souls were ready.
<br /><br />
              The next two souls to be extracted, SeekTheLight and TheJayswaby, were exactly what they were looking for. With their new team of six, they started building the foundation of the Cyber District and continued their search for new residents.
<br /><br />
              Join us if you dare wager your soul for a brighter future – we are awaiting your arrival.

          </Story>
         </div>
      </BackgroundLayer>
      <Footer className=""/>

     
     
    </>
  );
}