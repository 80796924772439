import React from 'react';
import { TailwindContainer, BackgroundLayer, SectionDivider, Card } from '../../';


function FutureVision({children, className, title="The District's Vision", ...props}) {

  return (
    <BackgroundLayer className={`${className}`} {...props}>
      <TailwindContainer className="py-4 md:px-8 mx-auto">
        <SectionDivider>{title}</SectionDivider>
        <div className="bg-opacity-70  px-4 lg:px-32  rounded-xl ">
          <p className=" text-sm sm:text-base md:text-lg text-center text-white ">{children}</p>
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default FutureVision

