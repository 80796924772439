import React,{useState} from 'react';
import { TailwindContainer,BackgroundLayer,Card} from '../../';
import { classNames } from '../../../Utilities';

const info = [
  {
    id: 1,
    title:"Total Supply of $SOUL",
    amount: "73,000,000"
  },
  {
    id: 2,
    title:"Max rewarded in season 1 to NFT holders",
    amount: "9,125,000"
  },
  {
    id: 3,
    title:"Max rewarded in season 2 to NFT holders",
    amount: "8,675,000"
  },
  {
    id: 4,
    title:"Max rewarded in season 3 to NFT holders",
    amount: "8,225,000"
  },
  {
    id: 5,
    title:"Max rewarded in season 4 to NFT holders",
    amount: "7,775,000"
  },
  {
    id: 6,
    title:"Max rewarded in season 5 to NFT holders",
    amount: "7,325,000"
  },
  {
    id: 7,
    title:"Max rewarded in season 6 to NFT holders",
    amount: "6,875,000"
  },
  {
    id: 8,
    title:"Max rewarded in season 7 to NFT holders",
    amount: "6,425,000"
  },
  {
    id: 9,
    title:"Max rewarded in season 8 to NFT holders",
    amount: "5,975,000"
  },
  {
    id: 10,
    title:"Max to honorary",
    amount: "263,700"
  },
  {
    id: 11,
    title:"Giveaways",
    amount: "1,000,000"
  },
  {
    id: 12,
    title:"Project Zero",
    amount: "11,336,300"
  },
]
function Tokenomics({children,title="Tokenomics",subtitle="Calculations based on 1 token given a day per NFT for five years",className,...props}) {
  const [activeId,setActiveId] = useState(-1);

  const onPress= (id) => {
    if(id == activeId){
      setActiveId(-1);
      return;
    }
    setActiveId(id);
  }

  return (
    <BackgroundLayer className={` ${className}`} {...props}>
      
      <TailwindContainer className="md:px-8 mx-auto">
        <h2 className="text-center text-3xl font-bold text-white sm:text-4xl mb-2">
          {title}
        </h2>
        <p class="text-xl text-center text-gray-300 mb-6  px-4">{subtitle}</p>
        <div className="mt-2 border-t-2 border-b-2 md:border-2 md:mb-4 border-graffiti-pink mx-auto grid grid-cols-2  bg-graffiti-blue-med-opacity md:rounded-xl">
          {
            info.map((infoItem,index) => 
              <>
                <div className={classNames(index == 0 ? 'text-xl sm:text-2xl py-6 ' : 'text-lg sm:text-xl py-5 ' ,infoItem.id == info.length ? '' : 'border-b' , 'border-r border-gray-600 px-4 flex items-center justify-center')} key={infoItem.id}>
                  <dt className="text-white text-center">
                    {infoItem.title}
                  </dt>
                </div>
                <div className={classNames(index == 0 ? 'text-xl sm:text-2xl py-6 ' : 'text-lg sm:text-xl py-5 ', infoItem.id == info.length ? '' : 'border-b' , ' px-4 flex items-center justify-center  border-gray-600')} key={infoItem.id}>
                  <dt className="text-white text-center ">
                    {infoItem.amount}
                  </dt>
                </div>
              </>
            )
          }
             
            
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default Tokenomics

