import React from 'react';
import { TailwindContainer, BackgroundLayer, Card} from '../../';


function AboutDistrict({children, className, ...props}) {
  return (
    <BackgroundLayer className={`pt-4 pb-2 ${className}`} {...props}>
      <TailwindContainer className="md:px-8 mx-auto">

      <Card className="bg-opacity-70 sm:px-4 ">
        <img className="w-[70%] mx-auto" src="/Assets/Images/hero-guy.png" />

        <div className="bg-hologram-blue bg-opacity-60 p-4 md:p-8 rounded-lg">
          <h1 className="text-center text-2xl md:text-5xl md:pb-4  uppercase text-graffiti-pink">About Cyber District</h1>
        
          <p className="mx-auto mt-2 text-sm sm:text-base md:text-lg text-center text-white  ">{children}</p>
        </div>
        
      </Card>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default AboutDistrict

