import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Home, About, Mint, Financials, FAQs, TermsOfService } from "./Pages"

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/financials" element={<Financials />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />


      </Routes>
    </div>
  );
}


