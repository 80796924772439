/* React Imports  */
import React, {useState, useEffect} from 'react';

/* CSS Imports  */

/* Component Imports  */
import { TailwindContainer, BackgroundLayer } from '../../';
/* Utils */
import {classNames} from '../../../Utilities';

/* Image Imports */
const image_paths = {
  RoadMapStart :'/Assets/Images/Roadmap/road-map-start.png',
  RoadMapMid_1 :'/Assets/Images/Roadmap/road-map-mid-1.png',
  RoadMapMid_2 :'/Assets/Images/Roadmap/road-map-mid-2.png',
  RoadMapEnd :'/Assets/Images/Roadmap/road-map-end.png',
  RoadMapEndFlip :'/Assets/Images/Roadmap/road-map-end-flip.png',
  DoxTeamLogo :'/Assets/Images/Roadmap/logo-dox-team.png',
  GiveawayLogo :'/Assets/Images/Roadmap/logo-giveaway.png',
  PartnershipLogo :'/Assets/Images/Roadmap/logo-partnership.png',
  WhiteListLogo :'/Assets/Images/Roadmap/logo-white-list.png',
  AMALogo :'/Assets/Images/Roadmap/ama-icon.png',
  NftRevealLogo :'/Assets/Images/Roadmap/nft-reveal-icon.png',
  DiscordLogo :'/Assets/Images/Roadmap/discord-icon.png',
  LandLogo :'/Assets/Images/Roadmap/land-icon.png',
  MarketingLogo :'/Assets/Images/Roadmap/marketing-icon.png',
  EventsLogo :'/Assets/Images/Roadmap/events-icon.png',
  RankingLogo :'/Assets/Images/Roadmap/ranking-icon.png',
  SoldOutLogo :'/Assets/Images/Roadmap/sold-out-icon.png',
  Season1Logo :'/Assets/Images/Roadmap/season-1-icon.png',
  Season2Logo :'/Assets/Images/Roadmap/season-2-icon.png',
  Season3Logo :'/Assets/Images/Roadmap/season-3-icon.png',
  Season4Logo :'/Assets/Images/Roadmap/season-4-icon.png',
}



//Homepage, 48:201
export default function RoadMap({id, sticky=false}) {
  var [pageIndex, setIndex] = useState(0);

  useEffect(() => {
  }, [pageIndex]);
  
  const roadMaps = [
    {title:"Pre-Sale", 
      info:[
        "Whitelisting begins, details on Discord ",
        "Full doxing of team",
        "Giveaway contests on Discord and Twitter",
        "Announcing partnerships"
      ],
      icons:[
        image_paths.WhiteListLogo,
        image_paths.DoxTeamLogo,
        image_paths.GiveawayLogo,
        image_paths.PartnershipLogo,
      ]
    },
    {title:"Season 1",      
      info:[
        "5000 NFTs Sold!",
        "NFTs Revealed!", 
        "Holders only section revealed on Discord",
        "Post sell out AMA", 
        "Rarity rankings on all websites",
        "Land buying begins", 
        "Marketing and sneak-peeks for season 2 begins",
        "Community events to be announced", 
      ],
      icons:[
        image_paths.SoldOutLogo,
        image_paths.NftRevealLogo,
        image_paths.DiscordLogo,
        image_paths.AMALogo,
        image_paths.RankingLogo,
        image_paths.LandLogo,
        image_paths.MarketingLogo,
        image_paths.EventsLogo
      ]
    },
    {
      title:"Year 1", 
      info:[
        "Season 1: End of January 2022 (Exact date TBD)",
        "Season 2: End of April 2022", 
        "Season 3: End of July 2022",
        "Season 4: End of October 2022"
      ],
      icons:[
        image_paths.Season1Logo,
        image_paths.Season2Logo,
        image_paths.Season3Logo,
        image_paths.Season4Logo,
      ]
    }
  ]
/*
        {sticky ? <div className="sticky z-40 mb-64 transform translate-y-16 md:translate-y-32 top-1/20 left-0 w-8 md:w-12 h-36 bg-red-500">
            <button onClick={() => setIndex(0)} className="bg-green-500">Pre-sale</button>
            <button onClick={() => setIndex(1)} className="bg-green-500">Season 1</button>
            <button onClick={() => setIndex(2)} className="bg-green-500">Year 1</button>

        </div> : null } 
*/

  //Need to swap text with Text components and chnage text type if no icons
  const desktopContainerSize = "h-36 md:h-64";
  /*
  const StartImg = <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={RoadMapStart} alt="Road Map Start"/>
  const Mid_1Img = <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={RoadMapMid_1} alt="Road Map Mid 1"/>
  const Mid_2Img = <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={RoadMapMid_2} alt="Road Map Mid 2"/>
  const EndImg =   <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={RoadMapEnd} alt="Road Map End"/>
*/
  return (
    <>  
    <BackgroundLayer className="">
      <TailwindContainer className="py-8 px-4 text-white items-center flex flex-col -mb-8 sm:-mb-24">
        <h2 className={" text-2xl font-semibold text-graffiti-pink"}>Road Maps</h2>
        <div className="relative m-auto road-map-max-width" id={id}>
          <div className="flex flex-row justify-evenly mb-4 mt-5 sm:mt-6 md:mt-8">
            {roadMaps.map((roadMap, key) => 
              <button onClick={() => setIndex(key)} key={key} className={classNames(pageIndex === key ? 'bg-graffiti-pink' :'bg-graffiti-pink-low-opacity','flex-grow mx-1 sm:mx-4 md:mx-12 text-sm md:text-base transition duration-700  hover:bg-graffiti-pink border border-graffiti-pink font-semibold px-4 py-2 rounded-md')}>{roadMap.title}</button>
            )}
          </div>
          <div className="text-white relative m-auto grid max-w-1200-px mt-4 sm:mt-6 md:mt-8  grid-cols-1 md:grid-cols-2">
            <div className="block">
              {roadMaps[pageIndex].info.map((infoText, key) => 
                <>
                  {key % 2 === 0 ? 
                  <div key={key} className={`${desktopContainerSize} hidden md:flex pl-16 flex-row sm:justify-center md:pr-20`}>
                    <div className="flex md:flex-col items-center">
                      {roadMaps[pageIndex].icons && roadMaps[pageIndex].icons[key]  ?
                        <img className="h-20 sm:h-32 md:h-56" src={roadMaps[pageIndex].icons[key] } alt={roadMaps[pageIndex].icons[key].alt} />
                        : <></>
                      }
                      <div className="px-2 md:py-2 text-lg md:text-2xl font-semibold md:text-center max-w-18-rem md:max-w-24-rem">{infoText}</div> 
                    </div>
                  </div> 
                  :  
                  <div key={key} className={`hidden md:block md:${desktopContainerSize}`}></div>
                  }
                </>
              )}
            </div>
            <div className="absolute left-4 transform-none sm:left-8/100 md:left-1/2 ">
              <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={image_paths.RoadMapStart} alt="Road Map Start"/>
              {[...Array(roadMaps[pageIndex].info.length-1)].map((x, index) => 
                <>
                  {index %2 === 0 
                  ? <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={image_paths.RoadMapMid_1} alt="Road Map Mid 1"/>
                  : <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={image_paths.RoadMapMid_2} alt="Road Map Mid 2"/>
                  }
                </>
              )}
              {
                roadMaps[pageIndex].info.length % 2 === 0 
                ? <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={image_paths.RoadMapEnd} alt="Road Map End"/>
                : <img className={`${desktopContainerSize} md:transform md:-translate-x-1/2`} src={image_paths.RoadMapEndFlip} alt="Road Map End Flipped"/>
              }
            </div>
            <div className="block">
              {roadMaps[pageIndex].info.map((infoText, key) => 
                <>
                  {key % 2 === 1 ? 
                  <div key={key} className={`${desktopContainerSize} flex pl-16 sm:pl-28 md:pl-20 flex-row  md:flex-col md:justify-start  md:pr-20`}>
                    <div className="flex md:flex-col items-center">
                      {roadMaps[pageIndex].icons && roadMaps[pageIndex].icons[key] ?
                        <img className="h-20 sm:h-32 md:h-56" src={roadMaps[pageIndex].icons[key] } alt={roadMaps[pageIndex].icons[key].alt} />
                        : <></>
                      }               
                      <div className="px-2 md:py-2 text-lg md:text-2xl font-semibold md:text-center max-w-18-rem md:max-w-24-rem">{infoText}</div>
                    </div>
                  </div> 
                  :  
                  <>
                    <div key={key} className={`hidden md:block md:${desktopContainerSize}`}></div>
                    <div key={key} className={`${desktopContainerSize} flex md:hidden pl-16 sm:pl-28 md:pl-20 flex-row md:flex-col md:justify-start  md:pr-20`}>
                      <div className="flex md:flex-col items-center">
                        {roadMaps[pageIndex].icons && roadMaps[pageIndex].icons[key] ?
                          <img className="h-20 sm:h-32 md:h-56" src={roadMaps[pageIndex].icons[key] } alt={roadMaps[pageIndex].icons[key].alt} />
                          : <></>
                        }               
                        <div className="px-2 md:py-2 text-lg md:text-2xl font-semibold md:text-center max-w-18-rem md:max-w-24-rem">{infoText}</div>
                      </div>
                    </div> 
                  </>
                  }
                </>
              )}
            </div>
          </div>
          <div className={`${desktopContainerSize} -mb-6 md:-mb-18`}></div>
        </div>
        </TailwindContainer>
      </BackgroundLayer>
    </>
  );
}
