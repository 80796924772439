import * as React from "react";
import { Link } from "react-router-dom";
import { Loading, Navbar, TailwindContainer, BackgroundLayer, SectionDivider, FAQSection, Glossary, Footer } from "../Components";

export default function FAQs() {
  return (
    <>
    <Navbar />
      <BackgroundLayer className="min-h-screen bg-black-blue">
        <FAQSection />
        <Glossary />
      </BackgroundLayer>
    <Footer />
    </>
  );
}