import React from 'react';
import { TailwindContainer, BackgroundLayer, SectionDivider } from '../../';


function LandUtility({children, className, title="Sandbox Land Utilities", ...props}) {

  return (
    <BackgroundLayer className={`  ${className}`} {...props}>
      <TailwindContainer className="mx-auto">
        <SectionDivider>{title}</SectionDivider>
        <div className="px-4 flex flex-col sm:flex-row items-center justify-center gap-8 lg:gap-4 lg:mx-32">
          <img className="w-36 sm:w-48 lg:w-[15%]" src="/Assets/Images/sandbox-hologram.png" />
          <p className="text-center sm:text-left text-sm sm:text-base md:text-lg text-white">{children}</p>
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default LandUtility

