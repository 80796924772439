import React from 'react'

//Image is a path
function BackgroundLayer({className, children, ...props}) {
  return (
    <div className={`w-full ${className}`} {...props}>
        {children}
    </div>
  )
}

export default BackgroundLayer
