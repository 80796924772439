import React, { useState } from 'react';
import { useMatch } from "react-router-dom";
import { TailwindContainer, Button, LinkWrapper } from "../../Components";
import { classNames } from "../../Utilities";
import "./styles.css"
function Navbar({className, ...props}) {
   console.log("Home", useMatch('/'))
   console.log("About", useMatch('/about'))
   
  const navigation = [
    {
        title:"Home",
        to:"/",
        current: useMatch("/")
    },
    {
        title:"About",
        to:"/about",
        current: useMatch("/about")
    },
    {
        title:"Financials",
        to:"/financials",
        current: useMatch("/financials")
    },
    {
        title:"Mint",
        to:"/mint",
        current: useMatch("/mint")
    },
    {
        title:"FAQs",
        to:"/faqs",
        current: useMatch("/faqs")
    },
  ]

  const [dropdown, setDropdown] = useState(false);
  
  return (
    <nav className={`fixed w-full z-50 top-0 pt-1 bg-black-blue shadow border-b border-gray-600 ${className}`} {...props}>
        <TailwindContainer className="px-4">
            <div className="flex justify-between md:justify-center h-16">
                <div className="flex">
                   <LinkWrapper className="flex-shrink-0 flex items-center" to="/"> 
                        <img className="block h-12 w-12 rounded-full p-0.5 bg-gray-700" src="/Assets/Images/new-logo.png"/>
                        <img className="block h-10 pl-3" src="/Assets/Images/cyber-district-logo-white.png"/>
                    </LinkWrapper>
                    <div className="hidden md:ml-4 md:flex ">
                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                        {
                            navigation.map( (navItem, index) => (
                                <>
                                    {
                                        <LinkWrapper className={`${navItem.current ? 'border-graffiti-purple border-b-4 bg-graffiti-pink-very-low-opacity' : '' } navButtonWrapper`} key={index} to={navItem.to}>
                                            <button className="navButton">
                                                {navItem.title}
                                            </button>
                                        </LinkWrapper>
                                    }
                                </>
                            ))
                        }
                    </div>
                </div>
               
                <div className="-mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <button type="button" onClick={() => setDropdown(!dropdown)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-graffiti-purple-low-opacity  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-800" aria-controls="mobile-menu" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    {/*
                        Icon when menu is closed.

                        Heroicon name: outline/menu

                        Menu open: "hidden", Menu closed: "block"
                    */}
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    {/*
                        Icon when menu is open.

                        Heroicon name: outline/x

                        Menu open: "block", Menu closed: "hidden"
                    */}
                    <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                </div>
            </div>
       </TailwindContainer>

        {/* Mobile menu, show/hide based on menu state. */}
        <div className={classNames(dropdown ? 'max-h-96' : ' max-h-0  delay-100',`transform transition-[max-height] duration-1000 ease-in-out md:hidden`)} id="mobile-menu">
            <div className={classNames(dropdown ? 'visible opacity-100 delay-300' : 'invisible opacity-0', 'transform transition-all duration-700 ease-in-out ')}>
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {
                    navigation.map( (navItem, index) => (
                        <>
                            {
                                <LinkWrapper 
                                    className={classNames(navItem.current ? 'border-indigo-800 text-graffiti-pink bg-graffiti-blue-med-opacity' : 'text-gray-500 hover:bg-graffiti-purple-low-opacity hover:border-indigo-800 hover:text-graffiti-pink border-transparent', 'transition-all duration-300 block pl-3 pr-4 py-2 border-l-4 ')} key={index} to={navItem.to}>
                                    <button className=" text-lg font-semibold">
                                        {navItem.title}
                                    </button>
                                </LinkWrapper>
                            }
                        </>
                    ))
                }
            </div>
        </div>
    </nav>
  )
}

export default Navbar
