import React from 'react'
import { TailwindContainer, BackgroundLayer } from '../../';
import "./styles.css";
function Hero({children, className, ...props}) {
  return (
    <BackgroundLayer className={`relative bg-[url('/public/Assets/Images/hero.png')] bg-repeat backdrop-brightness-[40%] bg-cover bg-bottom lg:bg-contain h-[32rem] sm:h-hero`}>

      <BackgroundLayer className={`bg-black bg-opacity-[.65] bg-center bg-no-repeat w-full h-full`}>

        <TailwindContainer className={`text-center h-full p-4 ${className}`} {...props}>

          <div className="relative flex flex-col justify-center items-center text-white mx-auto h-full pt-8  overflow-hidden">
            <img className=" block h-full object-cover overflow-hidden " src="/Assets/Images/hero-logo.png" alt="Cyber District Logo"/>
            <div className=" mission text-sm sm:text-base md:max-w-2xl rounded-xl px-4 mb-8 mt-2 sm:mt-2 sm:mb-8  font-semibold">
              {children}
            </div>
          </div>
        </TailwindContainer>
        <div className="absolute min-h-[10%] w-full bottom-0 bottomGradient"></div>
      </BackgroundLayer>
    </BackgroundLayer>

  )
}

export default Hero
/*absolute top-0 min-w-24 h-[50%] sm:h-fit sm:w-[90%] sm:-top-36 md:-top-40  md:w-fit md:h-[40rem]*/