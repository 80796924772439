import React, { useState, useEffect } from 'react'
import { classNames } from '../../../Utilities';
import './styles.css';//Add shadows, rounded cards if need be


function CarasouelSlider({children, items=[], className, ...props}) {

  const maxIndex = items.length-1;

  const [minus, setMinus] = useState(0);
  const [prev, setPrev] = useState(1);
  const [curr, setCurr] = useState(2);
  const [next, setNext] = useState(3);
  const [plus, setPlus] = useState(4);

  const logIndices = () => {
    console.log(  
      "Previous Index", prev, "Current Index", curr, "Next Index", next, "\n",
      "Minus", minus, "Plus", plus, "\n",
      "Max Index", maxIndex, "Items Length", items.length
    );
  }


  const clickPrev = () => {
    /* Setting New Current Index */
    var newCurr = curr + 1;
    if( newCurr > maxIndex ){
      newCurr = 0;
    }
    setCurr(newCurr);

    /* Setting New Previous Index */
    var newPrev = prev + 1;
    if( newPrev > maxIndex ){
      newPrev = 0;
    }
    setPrev(newPrev);

    /* Setting New Next Index */
    var newNext = next + 1;
    if( newNext > maxIndex ){
      newNext = 0;
    }
    setNext(newNext);

    /* Setting New Minus Index */
    var newMinus = minus + 1;
    if( newMinus > maxIndex ){
      newMinus = 0;
    }
    setMinus(newMinus);

    /* Setting New Plus Index */
    var newPlus = plus + 1;
    if( newPlus > maxIndex ){
      newPlus = 0;
    }
    setPlus(newPlus);
  }

  const clickNext = () => {
    /* Setting New Current Index */
    var newCurr = curr - 1;
    if( newCurr < 0 ){
      newCurr = maxIndex;
    }
    setCurr(newCurr);

    /* Setting New Previous Index */
    var newPrev = prev - 1;
    if( newPrev < 0 ){
      newPrev = maxIndex;
    }
    setPrev(newPrev);

    /* Setting New Next Index */
    var newNext = next - 1;
    if( newNext < 0 ){
      newNext = maxIndex;
    }
    setNext(newNext);

    /* Setting New Minus Index */
    var newMinus = minus - 1;
    if( newMinus < 0 ){
      newMinus = maxIndex;
    }
    setMinus(newMinus);

    /* Setting New Plus Index */
    var newPlus = plus - 1;
    if( newPlus < 0 ){
      newPlus = maxIndex;
    }
    setPlus(newPlus);
  }
  
  if(items.length < 5){
    return null;
  }

  return (//h-40 xs:h-sneak-peek sm-xs:h-64 md-xs:h-md-sneak-peek lg-xs:h-lg-sneak-peek 
      <div className={`relative w-full ${className}`} {...props}>
        <div className="w-[37%] invisible">{items[0]}</div>
        <button className="absolute w-1/12 left-0 top-[47%] transform -translate-y-1/2 z-40">
          <img className="w-full" src="/Assets/Images/arrow-left.png" onClick={clickPrev}/>
        </button>
        <button className="absolute w-1/12 right-0 top-[47%] transform -translate-y-1/2 z-40">
          <img className="w-full" src="/Assets/Images/arrow-right.png" onClick={clickNext}/>
        </button>
        { 
          items.map( (item, index) => (
          <>
            {
              (index !== curr && index !== prev && index !== next && index !== minus && index !== plus) ? null :
              <div key={index}
                className={
                  classNames(
                    index === curr ?  'w-1/4    left-1/2    -translate-x-1/2    -translate-y-[40%] currShadow' : '',
                    index === prev ?  'w-[24%]  left-[10%]                      -translate-y-[55%]  skew-y-[12deg] brightness-50 prevShadow' : '',
                    index === minus ? 'w-[24%]  -left-[10%]                     -translate-y-[65%]  skew-y-[12deg]  opacity-0' : '',
                    index === next ?  'w-[24%]  left-[76%]  -translate-x-[40%]  -translate-y-[55%]  -skew-y-[12deg]  brightness-50 nextShadow' : '',
                    index === plus ?  'w-[24%]  left-[76%] -translate-x-[0%]  -translate-y-[65%]    -skew-y-[12deg] opacity-0' : '',
                    `absolute top-1/2 transition-all transform duration-1000 rounded-xl`
                  )}>
                {item}
              </div>
            }
          </>
          ))
        }
      </div>
  )
}

export default CarasouelSlider
