import React from 'react'

function Card({children, className, ...props}) {
  return (
    <div className={`relative flex flex-col ${className}`} {...props}>
        {children}
    </div>
  )
}

export default Card
