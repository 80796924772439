import React from 'react'


function TailwindContainer({className, children, ...props}) { //Can add option for bg opacity here
  return (
    <div className={`max-w-7xl mx-auto md:px-6 lg:px-8 ${className}`} {...props}>
        {children}
    </div>
  )
}

export default TailwindContainer
