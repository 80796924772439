import React from 'react'
import "./styles.css";

function SectionDivider({children, title, ...props}) {
  return (
    <div class="inline-flex w-full items-center  pb-8">
      <div class="w-full border-t h-0  border-graffiti-pink borderShadow"></div>
      { children ?
        <span class="whitespace-nowrap px-4 py-2 bg-opacity-80 rounded-xl  text-xl text-gray-200 textShadow ">
          {children}
        </span>
        : null
      }
      <div class="w-full h-0 border-t  border-graffiti-pink borderShadow"></div>
    </div>
  )
}

export default SectionDivider
