import React from 'react';
import { TailwindContainer, BackgroundLayer, Card, LinkWrapper, Button } from '../../';
import "./styles.css";

const team = [
  { 
    id: 0,
    name: "Parth Patel",
    src: "/Assets/Images/Profiles/parth.png",
    title: "Founder (Lead Dev)",
    ig_handle:"parth.2035",
  },
  {
    id: 1, 
    name: "Muhammad Asif",
    src: "/Assets/Images/Profiles/Mo.png",
    title: "Founder (Project Lead)",
    twitter_handle:"official_mu00",
    ig_handle:"official_mu00",
  },
  {
    id: 2, 
    name: "Anuj Yadav",
    src: "/Assets/Images/Profiles/anuj.png",
    title: "Founder (Project Lead)",
    twitter_handle:"the_zeltrix",
    ig_handle:"the_zeltrix",
  },
  { 
    id: 3, 
    name: "Zhenbin Chen",
    src: "/Assets/Images/Profiles/zhen.png",
    title: "Founder (Art Lead)",
    twitter_handle:"zzzehn",
    ig_handle:"zehn_district",
  },
  {
    id: 4,
    name: "John Di Sanza",
    src: "/Assets/Images/Profiles/seek.png",
    title: "Community Manager",
    twitter_handle:"seekthelightnft",
    ig_handle:"seekthelightnft",
  },
  {
    id: 5, 
    name: "Javon Swaby",
    src: "/Assets/Images/Profiles/jay.png",
    title: "Assistant Community Manager",
    twitter_handle:"thejayswaby",
    ig_handle:"thejayswaby",
  },
]

function DoxxedTeam({children, className, title="Meet the District Architects", ...props}) {

  return (
    <BackgroundLayer className={`md:pb-8  ${className}`} {...props}>
      <TailwindContainer className="mx-auto">
          <div className="lg:mx-8 bg-graffiti-blue-med-opacity pt-16 pb-6 px-8 sm:px-6 lg:px-8 md:rounded-xl">  
            <div className="text-center text-white lg:text-left">
              <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl mb-2">{title}</h2>
              <p class="text-xl text-gray-300">{children}</p>

            </div>  
   
            <div class="space-y-12 mb-4 mt-8">
   
              <ul role="list" class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
                 {
                    team.map( (person, index) => (
                      <>
                        {
                           <li key={person.id} class="py-10 px-6 bg-slate-900 text-center rounded-lg xl:px-10 xl:text-left doxCard">
                            <div class="space-y-6 xl:space-y-10">
                              <img class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src={person.src} alt="Team Member"/>
                              <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                                <div class="font-medium text-lg leading-6 space-y-1">
                                  <h3 class="text-white">{person.name}</h3>
                                  <p class="text-graffiti-purple">{person.title}</p>
                                </div>

                                <ul role="list" class="flex justify-center space-x-5 ">
                                { person.twitter_handle ? 
                                  <li>
                                    <a href={'https://twitter.com/'+person.twitter_handle} className="text-gray-400 hover:text-gray-300">
                                      <span class="sr-only">Twitter</span>
                                      <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                      </svg>
                                    </a>
                                  </li>
                                  : null
                                }
                                { person.ig_handle ? 
                                  <li>
                                    <a href={'https://www.instagram.com/'+person.ig_handle} class="text-gray-400 hover:text-gray-300">
                                      <span class="sr-only">Instagram</span>
                                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                                      </svg>
                                    </a>
                                  </li>
                                  : null
                                }
                                </ul>
                              </div>
                            </div>
                          </li>
                        }
                      </>
                    ))
                }
               
                 
                
              </ul>

            </div>
            </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default DoxxedTeam

