import React from 'react';
import { TailwindContainer, BackgroundLayer, SectionDivider } from '../../';


function Story({children, className,  title="The District's Story", ...props}) {

  return (
    <BackgroundLayer className={` pb-4 ${className}`} {...props}>
      <TailwindContainer className="mx-auto">
        <SectionDivider>{title}</SectionDivider>
        <p className="px-4 text-center sm:text-left  mx-auto text-sm sm:text-base md:text-lg text-white lg:px-32">{children}</p>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default Story

