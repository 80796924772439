import React, {useState} from 'react';
import { TailwindContainer, BackgroundLayer, Card} from '../../';
import { classNames } from '../../../Utilities';

const faqs = [
  {
    id: 1,
    question:"What are Cyber District NFTs?",
    answer: "Cyber District NFTs are unique, ERC-721 tokens created on Ethereum blockchain, that will bring multiple utility benefits to their owners."
  },
  {
    id: 2,
    question:"Wen mint?",
    answer: "Last weekend of January. (Exact dates TBA)"
  },
  {
    id: 3,
    question:"Mint price?",
    answer: "$250USD presale, $300USD public"
  },
  {
    id: 4,
    question:"Why will there be 8 seasons?",
    answer: "This minting model allows us to dollar cost average into Sandbox $LAND decreasing risk and maximizing value overall market conditions."
  },
  {
    id: 5,
    question:"What is the Cyber District smart contract?",
    answer: "TBA"
  },
  {
    id: 6,
    question:"Are there benefits to holding multiple NFTs?",
    answer: "Yes, the more NFTs you own the more $SOUL you generate every day, allowing you to take advantage of more $SOUL utilities."
  },
  {
    id: 7,
    question:"Which wallets will be supported for the mint?",
    answer: "Metamask"
  },
  {
    id: 8,
    question:"How many whitelist spots are there, and how do I get one?",
    answer: "1000 total whitelist spots. For information on how to get whitelisted check out the whitelist channel in our discord."
  },
  {
    id: 9,
    question:"How many Cyber District NFTs will be minted?",
    answer: "Season 1 will have 5000 NFTs, with more seasons to come in the near future. Stay tuned to announcements in our discord to stay updated."
  },
]
function FAQSection({children, className, ...props}) {
  const [activeId, setActiveId] = useState(-1);

  const onPress= (id) => {
    if(id == activeId){
      setActiveId(-1);
      return;
    }
    setActiveId(id);
  }

  return (
    <BackgroundLayer className={` ${className}`} {...props}>
      
      <TailwindContainer className="md:px-8 mx-auto">
        <div className="py-8 lg:pt-12 lg:pb-8  mx-auto divide-y-2 divide-graffiti-pink">
          <h2 className="text-center text-3xl font-bold text-white sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 divide-y divide-graffiti-pink md:rounded-xl">
            {
              faqs.map((faqItem, index) => 
                <>
                  <div className={classNames(faqItem.id === 1 ? 'md:rounded-t-xl' : '', faqItem.id === faqs.length ? 'md:rounded-b-xl' : '', 'py-6 px-4 bg-graffiti-purple-low-opacity')} key={faqItem.id}>
                    <dt className="text-lg">
                      <button type="button" onClick={() => onPress(faqItem.id)} className="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0" aria-expanded="false">
                        <span className="font-medium text-white">
                          {faqItem.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">                     
                          <svg className={classNames(activeId === faqItem.id ? '-rotate-180': 'rotate-0' ,'h-6 w-6 transform transition-all duration-700')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLineCap="round" strokeLineJoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </span>
                      </button>
                    </dt>

                    <dd className={classNames(activeId === faqItem.id ? 'max-h-96 h-full opacity-100 ': 'max-h-0 h-0 opacity-0 ' , 'transition-all duration-700 mt-2 sm:pr-8 lg:pr-12')}>
                      <p className="text-base text-gray-400">
                        {faqItem.answer}
                      </p>
                    </dd>
                  </div>
                </>
              )
            }
          </dl>
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default FAQSection

