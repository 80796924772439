import React from 'react'
import { TailwindContainer, BackgroundLayer, CarasouelSlider, Card } from '../../';

const images = [
  <Card className="bg-graffiti-pink rounded-xl">
    <img className="w-full rounded-xl" src="/Assets/Images/SneakPeeks/sneak-peek-1.png" />
  </Card>,
  <Card className="bg-graffiti-pink rounded-xl">
    <img className="w-full rounded-xl" src="/Assets/Images/SneakPeeks/sneak-peek-2.png" />
  </Card>,
  <Card className="bg-graffiti-pink rounded-xl ">
    <img className="w-full rounded-xl" src="/Assets/Images/SneakPeeks/sneak-peek-3.png" />
  </Card>,
  <Card className="bg-graffiti-pink rounded-xl">
    <img className="w-full rounded-xl" src="/Assets/Images/SneakPeeks/sneak-peek-4.png" />
  </Card>,
  <Card className="bg-graffiti-pink rounded-xl">
    <img className="w-full rounded-xl" src="/Assets/Images/SneakPeeks/sneak-peek-5.png" />
  </Card>
]

function SneakPeeks({children, className, ...props}) {
  return (
    <BackgroundLayer className={` md:mt-8  md:pb-6 ${className}`} {...props}>
      <TailwindContainer className="max-w-7xl mx-auto md:px-6 lg:px-8">
        <div className="bg-graffiti-purple-med-opacity md:rounded-xl  py-4">
        <h1 className="text-center text-2xl sm:text-3xl font-bold text-graffiti-pink">Sneak Peeks</h1>
        <CarasouelSlider items={images} />
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default SneakPeeks
