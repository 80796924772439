import React from 'react';
import { TailwindContainer, BackgroundLayer, SectionDivider, Card} from '../../';


const glossary = [
  {
    id:0,
    word:'Utility Token',
    definition:' Tokens that are intended to have a future use or already provide some use'
  },
  {
    id:1,
    word:'Mints',
    definition:'The act of tokenizing an artwork'
  },
  {
    id:2,
    word:'Assets',
    definition:'Property owned by a person or company'
  },
  {
    id:3,
    word:'Burning Mechanism',
    definition:'Removing tokens from the available supply, increasing its relative scarcity.'
  },
  {
    id:4,
    word:'Dollar-Cost-Averaging',
    definition:'A trading technique to remove any short-term price speculation out of your investments and invest a set amount of money into an asset on a regular basis, disregarding the price action'
  },
  {
    id:5,
    word:'Metaverse',
    definition:'A virtual-reality space in which users can interact with a computer-generated environment and other users.'
  },
  {
    id:6,
    word:'Deflationary Mechanics',
    definition:'Tokens fashioned to reduce in supply over time using the burning mechanism'
  },
  {
    id:7,
    word:'Web 3',
    definition:'a decentralized version of the virtual world, where users can interact and collaborate intelligently without worrying about the central, data-specific repositories'
  },
  {
    id:8,
    word:'Staking',
    definition:'A process that involves committing your crypto assets to support a blockchain network and confirm transactions.'
  },
  {
    id:9,
    word:'Wallet',
    definition:'An NFT wallet is a cryptocurrency wallet that supports the blockchain protocol NFTs are built on.'
  },
  {
    id:10,
    word:'OpenSea',
    definition:'A peer-to-peer marketplace for NFTs, rare digital items, and crypto collectibles'
  },
  {
    id:11,
    word:'Metamask',
    definition:' A wallet that equips you with a key vault, secure login, token wallet, and token exchange basically, everything you need to manage your digital assets.'
  },
  {
    id:12,
    word:'Ethereum',
    definition:'A blockchain-based software platform that can be used for sending and receiving value globally with its native cryptocurrency, ether, without any third-party interference. But it can also do much more than that.'
  },
  {
    id:13,
    word:'Sandbox',
    definition:'A decentralized, community-driven gaming ecosystem where creators can share and monetize voxel assets and gaming experiences on the Ethereum blockchain.'
  },
  {
    id:14,
    word:'Floor price',
    definition:'A price on how low a price can be charged for a product, good, commodity, or service.'
  },
  {
    id:15,
    word:'HODL',
    definition:' Hold On for Dear Life'
  },
  {
    id:16,
    word:'Paper Hands',
    definition:'Someone who sells too early'
  },
  {
    id:17,
    word:'Diamond Hands',
    definition:'Holding on to an investment asset despite volatility and risks'
  },
  {
    id:18,
    word:'Gas',
    definition:'The fee required to successfully conduct a transaction on the Ethereum blockchain'
  },
  {
    id:19,
    word:'Royalty Fee',
    definition:'A payment made by one party to another that owns a particular asset, for the right to ongoing use of that asset'
  },
  {
    id:20,
    word:'Blockchain',
    definition:'A digital system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system'
  },
  {
    id:21,
    word:'Cryptocurrency',
    definition:'A digital payment system that doesn\'t rely on banks to verify transactions.'
  },
  {
    id:22,
    word:'Smart Contract',
    definition:'Programs stored on a blockchain that contain the required functions to operate blockchain technologies(ex. NFTs).'
  },
  {
    id:23,
    word:'Roadmap',
    definition:'A flexible planning technique to support strategic and long-range planning, by matching short-term and long-term goals'
  },
  {
    id:24,
    word:'Pre-Sale',
    definition:'A sale made before an item is made generally available for purchase to the public'
  },
  {
    id:25,
    word:'White List',
    definition:'A list of people considered to be acceptable for the presale'
  },
  {
    id:26,
    word:'FOMO',
    definition:'Fear Of Missing Out'
  },
  {
    id:27,
    word:'FUD',
    definition:'Fear Uncertainty Doubt'
  },
]

function Glossary({children, className, ...props}) {
  return (

    <BackgroundLayer className={` md:pb-8 ${className}`} {...props}>
      <TailwindContainer className="md:px-8 x-auto">

          <div class="lg:max-w-2xl lg:mx-auto lg:text-center">
            <h2 class="text-center text-3xl font-bold text-white sm:text-4xl">
              Glossary
            </h2>
          </div>
          <div class="mt-8 bg-graffiti-blue-med-opacity p-8 md:rounded-xl">
            <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
              {
                glossary.map((item, index) => 
                  <>
                     <div key={item.id}>
                        <dt class="font-semibold text-white">
                          {item.word}
                        </dt>
                        <dd class="mt-3 text-gray-400">
                          {item.definition}
                        </dd>
                      </div>
                  </>
                )
              }
            </dl>
          </div>
 
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default Glossary

