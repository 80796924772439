import React from 'react'

import './styles.css'

function Loading() {
  return (
    <div className="Loading">
      <img alt="loading" src="/Assets/Gifs/loading.gif" />
    </div>
  )
}

export default Loading
