import React, {useState} from 'react';
import { TailwindContainer, BackgroundLayer, Card} from '../../';
import { classNames } from '../../../Utilities';

const info = [
  {
    id: 1,
    title:"75% Land",
    subtitle: "To rent out and stake $SAND tokens on"
  },
  {
    id: 2,
    title:"10% $SAND Tokens",
    subtitle: "The more $LAND we own, the higher the staking rewards we get due to multipliers"
  },
  {
    id: 3,
    title:"5% Giveaways",
    subtitle: "For the community, by the community"
  },
  {
    id: 4,
    title:"5% Marketing",
    subtitle: "To keep growing the brand for future seasons"
  },
  {
    id: 5,
    title:"5% Founders and mods",
    subtitle: "Allows the team to give holders and the project 100% of their time"
  }
]

function Finances({children, className, ...props}) {
  const [activeId, setActiveId] = useState(-1);

  const onPress= (id) => {
    if(id == activeId){
      setActiveId(-1);
      return;
    }
    setActiveId(id);
  }

  return (
    <BackgroundLayer className={` ${className}`} {...props}>
      
      <TailwindContainer className="md:px-8 mx-auto">
        <div className="py-8 lg:pt-12 lg:pb-8  mx-auto">
          <h2 className="text-center text-3xl font-bold text-white sm:text-4xl px-4">
            Post Mint Finance Breakdown 
          </h2>
          <dl className="mt-6 divide-y divide-graffiti-pink md:rounded-xl md:border border-t-2 border-b-2 border-graffiti-pink">
            {
              info.map((faqItem, index) => 
                <>
                  <div className={classNames(faqItem.id === 1 ? 'md:rounded-t-xl' : '', faqItem.id === info.length ? 'md:rounded-b-xl' : '', 'py-6 px-4 bg-graffiti-purple-low-opacity text-white text-center')} key={faqItem.id}>
                      <div className="text-2xl mb-2">{faqItem.title}</div>
                      <div className="text-lg text-gray-500">{faqItem.subtitle}</div>

                  </div>
                </>
              )
            }
          </dl>
        </div>
      </TailwindContainer>
    </BackgroundLayer>
  )
}

export default Finances

